<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div v-if="!isLoading">
    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')} ${itemDelete.name}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteItem', { n: itemDelete.name }) }}</v-card-text>
        <v-card-text>{{ $t('msg.deleteToken') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR GENERATE -->
    <v-dialog
      v-model="isDialogVisible"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.regenerate')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.generateToken') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            :loading="generando"
            @click="generateApiKey()"
          >
            {{ $t('btn.send') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="isDialogVisible = !isDialogVisible"
          >
            {{ $t('btn.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="visibleCreateApiKey"
      scrollable
      max-width="450px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ $t('btn.generate') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="apikey.name"
                :label="$t('lbl.nameAPI')"
                outlined
                dense
                hide-details
                class="mt-5"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="visibleCreateApiKey = !visibleCreateApiKey"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            :loading="loading"
            @click="save"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR DESACTIVE TOKENS -->
    <v-dialog
      v-model="showEditTokenDialog"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ tokensEdicActive.name }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteToken') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="cancelDesactive()"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDesactive()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR ACTIVE TOKENS -->
    <v-dialog
      v-model="showEditTokenActiveDialog"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ tokensEdicActive.name }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.activeToken') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="cancelDesactive()"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDesactive()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR ACTIVE TOKENS PROPIOS -->
    <v-dialog
      v-model="showEditTokenActiveDialogPropio"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ tokensEdicActive.name }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.activeToken') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="cancelDesactivePropio()"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDesactivePropio()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR DESACTIVE TOKENS PROPIOS-->
    <v-dialog
      v-model="showEditTokenDialogPropio"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ tokensEdicActive.name }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteToken') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="cancelDesactivePropio()"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDesactivePropio()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-text>
      <v-row v-if="user.nivel >= 0 && pos === 0">
        <v-col
          v-if="(permisos.includes('api_keys:create') || permisos.includes('*')) && !offline"
          cols="12"
          md="2"
        >
          <!--<v-btn
            color="primary"
            class="mb-4 me-3"
            @click="openGenerateApiKey()"
          >
            <v-icon>{{ icons.mdiKeyChain }}</v-icon>
            <span>{{ $t('btn.generate') }}</span>
          </v-btn>-->
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :disabled="onlyInactivo"
            @click="visibleCreateApiKey = true"
          >
            <v-icon>{{ icons.mdiKeyChain }}</v-icon>
            <span>{{ $t('btn.generate') }}</span>
          </v-btn>
        </v-col>
      </v-row>

      <!--<v-row v-if="pos === 0 || pos === 1">
        <v-col
          cols="12"
          md="12"
        >
          <v-alert
            color="info"
            text
          >
            <p class="text-sm mb-0">
              {{ $t('msg.apiText1') }}
            </p>
            <p class="text-sm mb-0 pt-5">
              {{ $t('msg.apiText2') }}
            </p>
            <p class="text-sm mb-0 pt-5">
              {{ $t('msg.apiText3') }}
            </p>
          </v-alert>
        </v-col>
      </v-row>-->

      <v-row>
        <!--API DE AFILIADOS PROPIOS-->
        <template v-if="items.length > 0">
          <v-col
            v-for="(item, index) in items"
            :key="index * 15 + 150"
            cols="3"
          >
            <v-card
              elevation="24"
              outlined
            >
              <v-card-text>
                <p class="text-h6 text--primary">
                  {{ item.name }}
                  <!--<v-chip
                    v-if="!item.active"
                    color="error"
                  >
                    {{ $t('lbl.inactive') }}
                  </v-chip>-->
                </p>
                <div class="text--primary">
                  <b>API KEY:</b><br />
                  <v-tooltip
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <code
                        :id="`api_key${index}`"
                        class="d-inline-block text-truncate"
                        style="max-width: 180px;cursor: pointer;"
                        v-bind="attrs"
                        v-on="on"
                        @click="copyToClipboard(`api_key${index}`)"
                      >
                        {{ item.api_key }}
                      </code>
                    </template>
                    <span>{{ $t('btn.copy') }}</span>
                  </v-tooltip>
                  <br />

                  <b>SECRET TOKEN:</b> <br />
                  <v-tooltip
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <code
                        :id="`secret_token${index}`"
                        class="d-inline-block text-truncate mt-2"
                        style="max-width: 180px;cursor: pointer;"
                        v-bind="attrs"
                        v-on="on"
                        @click="copyToClipboard(`secret_token${index}`)"
                      >{{ item.secret_token }}</code>
                    </template>
                    <span>{{ $t('btn.copy') }}</span>
                  </v-tooltip>
                  <br />

                  <b>{{ $t('lbl.dateCreate') }}</b><br />
                  {{ item.date_update_token | moment('DD MMMM, YYYY, dddd') }}
                </div>
              </v-card-text>
              <v-card-actions>
                <v-tooltip
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      :disabled="!item.active"
                      v-on="on"
                      @click="goDoc(item)"
                    >
                      <v-icon>{{ icons.mdiLink }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ url_doc }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="permisos.includes('api_keys:edit') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      :disabled="!item.active"
                      v-on="on"
                      @click="editItem(item)"
                    >
                      <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.edit') }}</span>
                </v-tooltip>

                <v-tooltip
                  v-if="permisos.includes('api_keys:destroy') || permisos.includes('*')"
                  top
                  color="error"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItem(item)"
                    >
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.delete') }}</span>
                </v-tooltip>

                <v-spacer></v-spacer>
                <v-switch
                  v-model="item.active"
                  @change="editActiveTokenPropio(item)"
                ></v-switch>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-divider></v-divider>
          </v-col>
        </template>

        <!--API DE AFILIADOS-->
        <template v-if="companies.length > 0 && (user.nivel === 0 || user.nivel === -1)">
          <template v-for="iten in companies">
            <v-col
              v-for="(token, it) in iten.tokens"
              :key="it"
              cols="3"
            >
              <v-card
                elevation="24"
                outlined
              >
                <v-card-text>
                  <p>
                    <span class="text-h6 text--primary">
                      {{ iten.name === '*' ? 'Propietario' : `${iten.name}` }}<br />
                    </span>
                    <span class="text-h8 mt-0 pt-0">
                      {{ token.name }}
                    </span>
                  </p>
                  <div class="text--primary">
                    <b>API KEY:</b><br />
                    <!--<v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <code
                          :id="`api_key${it}`"
                          class="d-inline-block text-truncate"
                          style="max-width: 180px;cursor: pointer;"
                          v-bind="attrs"
                          v-on="on"
                          @click="copyToClipboard(`api_key${it}`)"
                        >
                          {{ token.api_key }}
                        </code>
                      </template>
                      <span>{{ $t('btn.copy') }}</span>
                    </v-tooltip>-->
                    <v-chip
                      :color="token.active ? 'primary' : 'error'"
                      class="text-decoration-line-through"
                    >
                      XXXXXXXXX
                    </v-chip>
                    <br />

                    <b>SECRET TOKEN:</b> <br />
                    <!--<v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <code
                          :id="`secret_token${it}`"
                          class="d-inline-block text-truncate mt-2"
                          style="max-width: 180px;cursor: pointer;"
                          v-bind="attrs"
                          v-on="on"
                          @click="copyToClipboard(`secret_token${it}`)"
                        >{{ token.secret_token }}</code>
                      </template>
                      <span>{{ $t('btn.copy') }}</span>
                    </v-tooltip>-->
                    <v-chip
                      :color="token.active ? 'primary' : 'error'"
                      class="text-decoration-line-through"
                    >
                      XXXXXXXXX
                    </v-chip>
                    <br />

                    <b>{{ $t('lbl.dateCreate') }}</b><br />
                    {{ token.date_update_token | moment('DD MMMM, YYYY, dddd') }}
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-switch
                    v-model="token.active"
                    :disabled="iten.name === '*'"
                    @change="editActiveToken(iten, token)"
                  ></v-switch>
                </v-card-actions>
              </v-card>
            </v-col>
          </template>
          <v-col
            cols="12"
            md="12"
          >
            <v-divider></v-divider>
          </v-col>
        </template>

        <!--GDS - NDC-->
        <template
          v-if="permisos.includes('api_tti:list') || permisos.includes('api_amadeus:list') || permisos.includes('*')"
        >
          <APIExtern
            :apis="api_gds"
            :user="user"
            type_api="gds"
          />
        </template>

        <!--PASARELAS DE PAGO-->
        <template
          v-if="
            permisos.includes('api_redsys:list') ||
              permisos.includes('api_sibs:list') ||
              permisos.includes('api_authorize_net:list') ||
              permisos.includes('*')
          "
        >
          <APIExtern
            :apis="api_payments"
            :user="user"
            type_api="payments"
          />
        </template>

        <!--CAMBIO DE MONEDA-->
        <template v-if="permisos.includes('currencyapi:list') || permisos.includes('api_bigdata:list') || permisos.includes('*')">
          <APIExtern
            :apis="api_exchanges_currencies"
            :user="user"
            type_api="exchanges_currencies"
          />
        </template>

        <!--CONTRATOS-->
        <template
          v-if="
            permisos.includes('api_hotetec:list') ||
              permisos.includes('api_copa_connect:list') ||
              permisos.includes('api_dingus:list') ||
              permisos.includes('api_solways:list') ||
              permisos.includes('api_juniper:list') ||
              permisos.includes('*')
          "
        >
          <APIExtern
            :apis="api_contrates"
            :user="user"
            type_api="contrates"
          />
        </template>

        <!--TRASLATE-->
        <template
          v-if="
            permisos.includes('api_deepl:list') ||
              permisos.includes('*')
          "
        >
          <APIExtern
            :apis="api_traslate"
            :user="user"
            type_api="traslate"
          />
        </template>
      </v-row>
    </v-card-text>

    <!--<v-tabs
      v-model="pos"
      icons-and-text
    >
      <v-tab>Afiliados Propios </v-tab>
      <v-tab v-if="user.nivel === 0 || user.nivel === -1">
        Afiliados
      </v-tab>
      <v-tab
        v-if="permisos.includes('api_tti:list') || permisos.includes('api_amadeus:list') || permisos.includes('*')"
      >
        GDS - NDC
      </v-tab>
      <v-tab
        v-if="
          permisos.includes('api_redsys:list') ||
            permisos.includes('api_sibs:list') ||
            permisos.includes('api_authorize_net:list') ||
            permisos.includes('*')
        "
      >
        Pasarelas de Pago
      </v-tab>
      <v-tab v-if="permisos.includes('currencyapi:list') || permisos.includes('*')">
        {{ $t('lbl.changeCurrency') }}
      </v-tab>
    </v-tabs>
    <v-card>
      <v-card-text>
        <v-tabs-items v-model="pos">
          <v-tab-item>
            <v-skeleton-loader
              v-if="generando"
              type="actions, actions"
            ></v-skeleton-loader>
            <v-simple-table v-if="items.length > 0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      class="text-uppercase"
                      width="20%"
                    >
                      {{ $t('lbl.name') }}
                    </th>
                    <th
                      class="text-uppercase"
                      width="25%"
                    >
                      API KEY
                    </th>
                    <th
                      class="text-uppercase"
                      width="20%"
                    >
                      SECRET TOKEN
                    </th>
                    <th
                      class="text-uppercase"
                      width="20%"
                    >
                      {{ $t('lbl.dateCreate') }}
                    </th>
                    <th
                      class="text-uppercase"
                      width="5%"
                    >
                      Doc.
                    </th>
                    <th
                      v-if="
                        permisos.includes('api_keys:edit') ||
                          permisos.includes('api_keys:destroy') ||
                          permisos.includes('*')
                      "
                      class="text-uppercase"
                      width="10%"
                    >
                      {{ $t('lbl.actions') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in items"
                    :key="index"
                  >
                    <td>
                      {{ item.name }}
                      <v-chip
                        v-if="!item.active"
                        color="error"
                      >
                        {{ $t('lbl.inactive') }}
                      </v-chip>
                    </td>
                    <td>
                      <code
                        :id="`api_key${index}`"
                        class="d-inline-block text-truncate mt-2"
                        style="max-width: 150px;"
                      >{{ item.api_key }}</code>
                      <v-tooltip
                        top
                        color="primary"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="copyToClipboard(`api_key${index}`)"
                          >
                            <v-icon>{{ icons.mdiLinkVariant }}</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('btn.copy') }}</span>
                      </v-tooltip>
                    </td>
                    <td class="text-truncate">
                      <code
                        :id="`secret_token${index}`"
                        class="d-inline-block text-truncate mt-2"
                        style="max-width: 150px;"
                      >{{ item.secret_token }}</code>
                      <v-tooltip
                        top
                        color="primary"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="copyToClipboard(`secret_token${index}`)"
                          >
                            <v-icon>{{ icons.mdiLinkVariant }}</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('btn.copy') }}</span>
                      </v-tooltip>
                    </td>
                    <td>
                      {{ item.date_update_token | moment('DD MMMM, YYYY, dddd') }}
                    </td>
                    <td>
                      <v-tooltip
                        top
                        color="primary"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="goDoc(item)"
                          >
                            <v-icon>{{ icons.mdiLink }}</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ url_doc }}</span>
                      </v-tooltip>
                    </td>
                    <td
                      v-if="
                        permisos.includes('api_keys:edit') ||
                          permisos.includes('api_keys:destroy') ||
                          permisos.includes('*')
                      "
                    >
                      <v-tooltip
                        v-if="permisos.includes('api_keys:edit') || permisos.includes('*')"
                        top
                        color="primary"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            :disabled="onlyInactivo"
                            v-on="on"
                            @click="editItem(item)"
                          >
                            <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('btn.edit') }}</span>
                      </v-tooltip>

                      <v-tooltip
                        v-if="permisos.includes('api_keys:destroy') || permisos.includes('*')"
                        top
                        color="error"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            :disabled="onlyInactivo"
                            v-on="on"
                            @click="deleteItem(item)"
                          >
                            <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('btn.delete') }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
          <v-tab-item v-if="user.nivel === 0 || user.nivel === -1">
            <v-simple-table v-if="companies.length > 0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-uppercase">
                      {{ $t('lbl.company') }}
                    </th>
                    <th
                      class="text-uppercase"
                      with="350px"
                    >
                      TOKENS
                    </th>
                    <th class="text-uppercase">
                      {{ $t('lbl.dateCreate') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(iten, index) in companies"
                    :key="index"
                  >
                    <td>
                      {{ iten.name === '*' ? 'Propietario' : iten.name }}
                    </td>
                    <td>
                      <fragment
                        v-for="(token, it) in iten.tokens"
                        :key="it"
                      >
                        <v-row>
                          <v-col
                            cols="12"
                            md="6"
                            class="text-center"
                          >
                            <p class="mr-5 mt-5">
                              {{ token.name }}
                            </p>
                          </v-col>
                          <v-col
                            cols="12"
                            md="3"
                          >
                            <v-chip
                              :color="token.active ? 'primary' : 'error'"
                              class="mt-3 text-decoration-line-through"
                            >
                              XXXXXXXXX
                            </v-chip>
                          </v-col>
                          <v-col
                            cols="12"
                            md="3"
                          >
                            <v-switch
                              v-model="token.active"
                              :disabled="iten.name === '*'"
                              @change="editActiveToken(iten, token)"
                            ></v-switch>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                      </fragment>
                    </td>
                    <td>
                      <fragment
                        v-for="(token, it) in iten.tokens"
                        :key="it"
                      >
                        <v-row>
                          <v-col
                            cols="12"
                            md="12"
                            class="text-center"
                          >
                            <p class="mr-5 mt-5">
                              {{ token.date_update_token | moment('DD MMMM, YYYY, dddd') }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                      </fragment>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-card-text v-else>
              <v-alert
                color="info"
                text
              >
                <p class="text-sm mb-0">
                  {{ $t('msg.apiTextNoItems') }}
                </p>
              </v-alert>
            </v-card-text>
          </v-tab-item>
          <v-tab-item
            v-if="permisos.includes('api_tti:list') || permisos.includes('api_amadeus:list') || permisos.includes('*')"
          >
            <APIExtern
              :apis="api_gds"
              :user="user"
              type_api="gds"
            />
          </v-tab-item>
          <v-tab-item
            v-if="
              permisos.includes('api_redsys:list') ||
                permisos.includes('api_sibs:list') ||
                permisos.includes('api_authorize_net:list') ||
                permisos.includes('*')
            "
          >
            <APIExtern
              :apis="api_payments"
              :user="user"
              type_api="payments"
            />
          </v-tab-item>
          <v-tab-item v-if="permisos.includes('currencyapi:list') || permisos.includes('*')">
            <APIExtern
              :apis="api_exchanges_currencies"
              :user="user"
              type_api="exchanges_currencies"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>-->
  </div>
</template>

<script>
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiKeyChain,
  mdiLinkVariant,
  mdiLink,
} from '@mdi/js'
import APIExtern from '../utils/APIExtern.vue'

export default {
  components: {
    APIExtern,
  },
  data() {
    return {
      pos: 0,
      url_doc: process.env.VUE_APP_DOC_URL,
      isLoading: true,
      generando: false,
      search: '',
      items: [],
      onlyInactivo: false,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiKeyChain,
        mdiLinkVariant,
        mdiLink,
      },
      isDialogVisible: false,
      isLoad: true,
      permisos: localStorage.getItem('permisos_auth'),
      itemDelete: {},
      itemRegenerate: {},
      isDialogVisibleDelete: false,
      companies: [],
      visibleCreateApiKey: false,
      apikey: {},
      loading: false,
      itemEdicActive: {},
      tokensEdicActive: {},
      showEditTokenDialog: false,
      showEditTokenDialogPropio: false,
      showEditTokenActiveDialog: false,
      showEditTokenActiveDialogPropio: false,
      user: {},
      offline: false,
      api_gds: [],
      api_payments: [],
      api_exchanges_currencies: [],
      api_contrates: [],
      api_traslate: [],
    }
  },
  mounted() {
    this.getCompanies()
    this.profile()
    this.load()
  },
  created() {
    this.getAPIGDS()
    this.getAPIPayments()
    this.getAPIEchangesCurrencies()
    this.getAPIContrate()
    this.getAPITraslate()
  },
  methods: {
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          this.user = response.data.data
          if (this.user.company_id) {
            if (this.user.company) {
              if (this.user.company.offline) {
                this.offline = this.user.company.offline
              }
            }
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    getCompanies() {
      this.axios
        .get('api_keys/companies', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          this.companies = response.data.data
        })
    },
    getAPIGDS() {
      this.axios
        .post(
          'api_extern',
          { type: 'gds' },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(response => {
          const result = response.data.data
          result.forEach(element => {
            if (element.slug === 'tti-zenith' && this.permisos.includes('api_tti:list')) {
              this.api_gds.push(element)
            } else if (element.slug === 'amadeus' && this.permisos.includes('api_amadeus:list')) {
              this.api_gds.push(element)
            }
          })
        })
    },
    getAPIPayments() {
      this.axios
        .post(
          'api_extern',
          { type: 'payments' },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(response => {
          const result = response.data.data
          result.forEach(element => {
            if (element.slug === 'redsys' && this.permisos.includes('api_redsys:list')) {
              this.api_payments.push(element)
            } else if (element.slug === 'sibs' && this.permisos.includes('api_sibs:list')) {
              this.api_payments.push(element)
            } else if (element.slug === 'authorize-net' && this.permisos.includes('api_authorize_net:list')) {
              this.api_payments.push(element)
            } else if (element.slug === 'paypal' && this.permisos.includes('api_paypal:list')) {
              this.api_payments.push(element)
            }
          })
        })
    },
    getAPIEchangesCurrencies() {
      this.axios
        .post(
          'api_extern',
          { type: 'exchanges_currencies' },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(response => {
          const result = response.data.data
          result.forEach(element => {
            if (element.slug === 'currencyapi' && this.permisos.includes('currencyapi:list')) {
              this.api_exchanges_currencies.push(element)
            } else if (element.slug === 'bigdata' && this.permisos.includes('api_bigdata:list')) {
              this.api_exchanges_currencies.push(element)
            }
          })
        })
    },
    getAPIContrate() {
      this.axios
        .post(
          'api_extern',
          { type: 'contrates' },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(response => {
          const result = response.data.data
          result.forEach(element => {
            if (element.slug === 'hotetecapi' && this.permisos.includes('api_hotetec:list')) {
              this.api_contrates.push(element)
            } else if (element.slug === 'copa_connect_api' && this.permisos.includes('api_copa_connect:list')) {
              this.api_contrates.push(element)
            } else if (element.slug === 'dingusapi' && this.permisos.includes('api_dingus:list')) {
              this.api_contrates.push(element)
            } else if (element.slug === 'solwaysapi' && this.permisos.includes('api_solways:list')) {
              this.api_contrates.push(element)
            } else if (element.slug === 'juniperapi' && this.permisos.includes('api_juniper:list')) {
              this.api_contrates.push(element)
            }
          })
        })
    },
    getAPITraslate() {
      this.axios
        .post(
          'api_extern',
          { type: 'traslate' },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(response => {
          const result = response.data.data
          result.forEach(element => {
            if (element.slug === 'deeplapi' && this.permisos.includes('api_deepl:list')) {
              this.api_traslate.push(element)
            }
          })
        })
    },
    load() {
      this.axios
        .get('api_keys', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
              // this.$store.commit('logout')
            }
          } else {
            this.items = res.data.data
            this.onlyInactivo = res.data.inactivos
          }
        })
    },
    openGenerateApiKey() {
      if (this.item.api_key) {
        this.isDialogVisible = true
      } else {
        this.generateApiKey()
      }
    },
    generateApiKey() {
      this.generando = true
      this.axios
        .post('api_keys/generate', this.itemRegenerate, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
              // this.$store.commit('logout')
            } else if (res.data.data.status === 412) {
              this.$toast.error(this.$t('msg.noAsociedCompany'))
            }
          } else {
            this.load()
            this.$toast.success(this.$t('msg.successToken'))
          }
        })
        .finally(() => {
          this.generando = false
          this.isDialogVisible = false
        })
    },
    copyToClipboard(id) {
      const copyText = document.getElementById(id).textContent
      navigator.clipboard.writeText(copyText)

      // document.execCommand('copy')
      this.$toast.info(this.$t('msg.copyText', { n: copyText }))

      // this.$toast.info(this.$t('msg.copyText', { n: id }))
    },
    showModal(item) {
      this.item = item
      this.isLoad = true
      if (item.permisos !== null) {
        this.abilities = item.permisos.abilities
      } else {
        this.abilities = []
      }
      this.loadPermisos()
      this.isDialogVisible = !this.isDialogVisible
    },
    loadPermisos() {
      this.abilityList = []
      this.axios
        .get('permiso?per_page=1000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const items = res.data.data
          // eslint-disable-next-line no-plusplus
          for (let indexA = 0; indexA < this.abilities.length; indexA++) {
            const ability = this.abilities[indexA]
            if (ability === '*') {
              // eslint-disable-next-line no-plusplus
              for (let index = 0; index < items.length; index++) {
                const element = items[index]
                this.abilityList.push(element.name)
              }
            } else {
              // eslint-disable-next-line no-plusplus
              for (let index = 0; index < items.length; index++) {
                const element = items[index]
                if (element.ability === ability) {
                  this.abilityList.push(element.name)
                }
              }
            }
          }
          // eslint-disable-next-line no-return-assign
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoad = false))
    },
    closeDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    closeDialogDelete() {
      this.isDialogVisibleDelete = !this.isDialogVisibleDelete
      this.itemDelete = {}
    },
    newItem() {
      localStorage.removeItem('api_keys-id')
      this.$router.push({ name: 'api_keys-update' })
    },
    editItem(iten) {
      this.itemRegenerate = iten
      this.isDialogVisible = true
    },
    editActive(iten) {
      this.axios
        .post(
          `api_keys/active/${iten.id}`,
          { active: iten.active },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          }
        })
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.itemDelete = iten
    },
    confirmDelete() {
      this.loading = true
      this.axios
        .post('api_keys/delete-token', this.itemDelete, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.load()
          }
        })
        .finally(() => this.closeDialogDelete())
    },

    save() {
      if (this.apikey.name) {
        this.loading = true

        this.axios
          .post(
            'api_keys/crear',
            { name: this.apikey.name },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            if (res.data.success === false) {
              if (res.data.data.status === 401) {
                // this.$toast.error(this.$t('msg.noAutorice'))
                // this.$store.commit('logout')
              } else if (res.data.data.status === 412) {
                this.$toast.error(this.$t('msg.noAsociedCompany'))
              }
            } else {
              this.load()
              this.$toast.success(this.$t('msg.successToken'))
            }
          })
          .finally(() => {
            this.loading = false
            this.visibleCreateApiKey = false
            this.apikey = {}
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    editActiveTokenPropio(iten) {
      this.tokensEdicActive = iten
      if (!iten.active) {
        // this.confirmDesactive()
        this.showEditTokenDialogPropio = true
      } else {
        this.showEditTokenActiveDialogPropio = true
      }
    },
    editActiveToken(iten, token) {
      this.itemEdicActive = iten
      this.tokensEdicActive = token
      if (token.active) {
        // this.confirmDesactive()
        this.showEditTokenActiveDialog = true
      } else {
        this.showEditTokenDialog = true
      }
    },
    confirmDesactive() {
      this.loading = true
      this.axios
        .post(`api_keys/edit-token/${this.itemEdicActive.id}`, this.tokensEdicActive, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.getCompanies()
          }
        })
        .finally(() => this.closeDialogDesactive())
    },
    confirmDesactivePropio() {
      this.loading = true
      this.axios
        .post(`api_keys/edit-token/${this.tokensEdicActive.company_id}`, this.tokensEdicActive, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.load()
          }
        })
        .finally(() => this.closeDialogDesactive())
    },
    cancelDesactive() {
      this.closeDialogDesactive()
      this.getCompanies()
    },
    cancelDesactivePropio() {
      this.closeDialogDesactive()
      this.load()
    },
    closeDialogDesactive() {
      this.loading = false
      this.showEditTokenDialogPropio = false
      this.showEditTokenDialog = false
      this.showEditTokenActiveDialog = false
      this.showEditTokenActiveDialogPropio = false
      this.itemEdicActive = {}
      this.tokensEdicActive = {}
    },

    goDoc(item) {
      // eslint-disable-next-line camelcase
      const { api_key } = item
      // eslint-disable-next-line camelcase
      const { secret_token } = item

      // eslint-disable-next-line camelcase
      const url = `${this.url_doc}login?api_key=${api_key}&secret_token=${secret_token}`

      /* const route = this.$router.resolve({
        name: 'UserProfileView',
        params: {id: participantUser.user_id},
      }) */
      window.open(url, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
